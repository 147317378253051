import AuthPassedMiddleware from "@/middlewares/AuthPassedMiddleware";
import AuthFailedMiddleware from "@/middlewares/AuthFailedMiddleware";
import LocaleService from "@/services/LocaleService";

const authRoutes = [
	{
		name: "auth.passed",
		path: "",
		component: () => import("@/views/Blank.vue"),
		meta: {
			middleware: [AuthPassedMiddleware],
		},
		children: [
			{
				path: "",
				redirect: {
					name: "main",
					params: {
						locale: LocaleService.getLocale(),
					},
				},
			},
			{
				name: "auth.logout",
				path: "logout",
				component: () => import("@/views/pages/auth/AuthLogout.vue"),
			},
		],
	},
	{
		name: "auth.failed",
		path: "",
		component: () => import("@/views/Blank.vue"),
		meta: {
			middleware: [AuthFailedMiddleware],
		},
		children: [
			{
				name: "auth.login",
				path: "login",
				component: () => import("@/views/pages/auth/AuthLogin.vue"),
			},
			// {
			// 	name: "auth.register",
			// 	path: "register",
			// 	component: () => import("@/views/Blank.vue"),
			// },
			// {
			// 	path: "register/confirm",
			// 	redirect: {
			// 		name: "auth.login",
			// 		params: {
			// 			locale: LocaleService.getLocale(),
			// 		},
			// 	},
			// },
			// {
			// 	name: "auth.register.confirm",
			// 	path: "register/confirm/:id",
			// 	component: () => import("@/views/Blank.vue"),
			// },
			{
				name: "auth.password.forgot",
				path: "password-forgot",
				component: () => import("@/views/pages/auth/AuthPasswordForgot.vue"),
			},
			{
				name: "auth.password.reset",
				path: "password-reset",
				component: () => import("@/views/pages/auth/AuthPasswordReset.vue"),
			},
		],
	},
];

export default authRoutes;
