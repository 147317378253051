import {defineStore} from "pinia";
import SanctumRepository from "@/repositories/SanctumRepository";

export const useSanctumStore = defineStore('sanctum',{
	state: () => ({ }),

	getters: { },

	actions: {
		csrf() {
			return new Promise((resolve, reject) => {
				SanctumRepository.csrfToken()
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		}
	}
});