import "./scss/styles.scss";
import { createVuetify } from "vuetify";

const effectglass = {
	dark: false,
	colors: {
		background: "#FFFFFF",
		surface: "#FFFFFF",
		primary: "#0D2346",
		secondary: "#C60033",
		tertiary: "#1A51A0",
		error: "#753037",
		info: "#4d63e8",
		success: "#326F41",
		warning: "#dc6c2f",

		"color-1": "#ffffff",
		"color-2": "#1c1c1c",
		"color-3": "#0d2346",
		"color-4": "#1a51a0",
		"color-5": "#c60033",
		"color-6": "#222e3e",
		"color-7": "#636c77",
		"color-8": "#b3bac5",
		"color-9": "#F1F2F3",
	},
	variables: {
		// 'medium-emphasis-opacity': 1 // Definicja wartości zmiennej w v-root
	},
};

const vuetify = createVuetify({
	theme: {
		defaultTheme: "effectglass",
		themes: {
			effectglass: effectglass,
		},
	},
});

export default vuetify;
