import PermissionsMiddleware from "@/middlewares/PermissionsMiddleware";

export default function MiddlewareController(context) {
	if (!context)
		return;

	const middlewares = setupMiddlewares(context.to);

	if (!middlewares.length)
		return context.next();

	return pipeline(context, middlewares);
}

function pipeline (context, middlewares, index = 0) {
	const nextMiddleware = middlewares[index];

	if (!nextMiddleware)
		return context.next;

	return () => {
		const nextPipeline = pipeline(context, middlewares, (index + 1));
		nextMiddleware({ ...context, next: nextPipeline });
	}
}

function setupMiddlewares(to) {
	if (!to)
		return [];
	if (!to.matched || !to.matched.length)
		return [];

	const middlewares = to.matched.reduce((result, to) => {
		if (!!to.meta.middleware && !!to.meta.middleware.length) {
			result = [...result, ...to.meta.middleware];
		}

		if (to.meta.permissions) {
			result.push((context) => {
				return PermissionsMiddleware({...context, permissions: to.meta.permissions});
			})
		}
		return result;
	}, []);

	return middlewares;
}