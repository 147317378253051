<template>
	<router-view/>
</template>

<script>
export default {
	name: "App",
	setup() { }
}
</script>

<style scoped>

</style>
