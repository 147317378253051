import LocaleService from "@/services/LocaleService";
import RouterService from "@/services/RouterService";
import RoutesService from "@/services/RoutesService";
import { useAuthStore } from "@/stores/useAuthStore";

export const PermissionsMiddleware = ({ to, from, next, permissions }) => {
	if (!permissions) {
		return next();
	}

	if (typeof permissions == "string") {
		permissions = [permissions];
	}

	const authStore = useAuthStore();

	if (permissions.length && !authStore.hasPermissions(permissions)) {
		return RouterService.push({ name: RoutesService.routes.error403, params: { locale: LocaleService.getLocale() } });
	}

	return next();
};

export default PermissionsMiddleware;
