export const commentNode = (el, vnode, content = " ") => {
	const comment = document.createComment(content);

	Object.defineProperty(comment, "setAttribute", {
		value: () => undefined,
	});

	vnode.text = " ";
	vnode.elm = comment;
	vnode.isComment = true;
	vnode.context = undefined;
	vnode.tag = undefined;

	if (vnode.componentInstance) {
		vnode.componentInstance.$el = comment;
	}

	if (el.parentNode) {
		el.parentNode.replaceChild(comment, el);
	}
};
export default { commentNode };
