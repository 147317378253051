import { defineStore } from "pinia";
import AuthRepository from "@/repositories/AuthRepository";

export const useAuthStore = defineStore("auth", {
	state: () => ({
		user: null,
		permissions: [],
		serviceMessage: null,
	}),

	getters: {
		getUser(state) {
			return state.user;
		},
		getUserRoleId(state) {
			if (!state.user) return false;

			return state.user.role_id;
		},
		getIsAuthenticated(state) {
			return state.user !== null;
		},
		getUserPermissions(state) {
			return state.permissions;
		},
		hasPermissions(state) {
			return (permissions) => {
				if (!permissions) return true;
				if (!this.getUserPermissions) return false;
				if (typeof permissions === "string") permissions = [permissions];

				return permissions.reduce((result, permission) => {
					result &= this.getUserPermissions.includes(permission);
					return result;
				}, true);
			};
		},
		getServiceMessage(state) {
			return state.serviceMessage;
		},
	},

	actions: {
		check() {
			return new Promise((resolve, reject) => {
				AuthRepository.check()
					.then((result) => {
						this.updateUser(result.data.data.user);
						this.serviceMessage = result.data.service_works.message;
						resolve(result);
					})
					.catch((result) => {
						this.clearUser();
						reject(result);
					});
			});
		},
		login(credentials) {
			return new Promise((resolve, reject) => {
				AuthRepository.login(credentials)
					.then((result) => {
						this.updateUser(result.data.data.user);
						resolve(result);
					})
					.catch((result) => {
						this.clearUser();
						reject(result);
					});
			});
		},
		logout() {
			return new Promise((resolve, reject) => {
				AuthRepository.logout()
					.then((result) => {
						this.clearUser();
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		passwordForgot(data) {
			return new Promise((resolve, reject) => {
				AuthRepository.passwordForgot(data)
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		passwordReset(data) {
			return new Promise((resolve, reject) => {
				AuthRepository.passwordReset(data)
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		update(data) {
			return new Promise((resolve, reject) => {
				AuthRepository.update(data)
					.then((result) => {
						this.updateUser(result.data.data);
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		updateUser(user) {
			this.user = user;
			this.savePermissions();
		},
		clearUser() {
			this.$reset();
			this.destroyPermissions();
		},
		deleteAccount() {
			return new Promise((resolve, reject) => {
				AuthRepository.deleteAccount()
					.then((result) => {
						resolve(result);
					})
					.catch((result) => {
						reject(result);
					});
			});
		},
		savePermissions() {
			this.permissions = this.user.permissions;
		},
		destroyPermissions() {
			this.permissions = [];
		},
	},
});
