import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

export const useRepositoryStore = defineStore("repository", {
	state: () => ({
		queryParams: useLocalStorage("pinia/repository/queryParams", {}),
	}),

	getters: {
		getQueryParams: (state) => {
			return state.queryParams;
		},
		getQueryParam: (state) => {
			return (param) => {
				if (!param) return null;
				if (!(param in state.queryParams)) return null;

				return state.queryParams[param];
			};
		},
	},

	actions: {
		addQueryParam(key, value) {
			if (value === null) {
				this.deleteQueryParam(key);
				return;
			}

			this.queryParams = {
				...this.queryParams,
				[key]: value,
			};
		},
		deleteQueryParam(key) {
			delete this.queryParams[key];
		},
	},
});
