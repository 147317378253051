import {useAuthStore} from "@/stores/useAuthStore";
import LocaleService from "@/services/LocaleService";
import RouterService from "@/services/RouterService";
import RoutesService from "@/services/RoutesService";

export const AuthFailedMiddleware = ({to, next}) => {
	const authStore = useAuthStore();

	authStore.check()
		.then((response) => {
			const locale = to.params.locale? to.params.locale : LocaleService.getLocale();
			return RouterService.push({ name: RoutesService.defaultRoute(), params: { locale: locale } });
		})
		.catch((response) => {
			return next();
		});
}

export default AuthFailedMiddleware;