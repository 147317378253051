import { useAuthStore } from "@/stores/useAuthStore";
import CommentNodeService from "@/services/CommentNodeService";

export default {
	can: {
		mounted: (el, binding, vnode, prevVnode) => {
			const authStore = useAuthStore();
			if (!!authStore.hasPermissions(binding.value)) return true;

			if (!!binding.modifiers.disabled) {
				el.style.display = "none";
				return true;
			}

			CommentNodeService.commentNode(el, vnode, "v-can");

			return true;
		},
	},
};
