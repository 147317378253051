const langFiles = import.meta.glob('./*/*.json', { eager: true });
const messages = {};

for (const path in langFiles) {
	let pathSplit = path.split('/');
	let lang = pathSplit[1];
	let moduleName = pathSplit[2].split('.')[0];
	if (!messages[lang]) {
		messages[lang] = {};
	}
	messages[lang][moduleName] = langFiles[path][moduleName];
}

export default messages;