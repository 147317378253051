import {useAuthStore} from "@/stores/useAuthStore";
import LocaleService from "@/services/LocaleService";
import RouterService from "@/services/RouterService";
import RoutesService from "@/services/RoutesService";

export const AuthPassedMiddleware = ({to, next}) => {
	const authStore = useAuthStore();

	if (authStore.getIsAuthenticated) {
		return next();
	}

	authStore.check()
		.then((response) => {
			return next();
		})
		.catch((response) => {
			const locale = to.params.locale? to.params.locale : LocaleService.getLocale();
			return RouterService.push({ name: RoutesService.routes.login, params: { locale: locale } });
		});
}

export default AuthPassedMiddleware;