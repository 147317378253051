export const routes = {
	error403: "error.403",
	error404: "error.404",

	home: "dashboard",

	dashboard: "dashboard",

	profile: "profile",

	login: "auth.login",
	logout: "auth.logout",
	passwordForgot: "auth.password.forgot",
	passwordReset: "auth.password.reset",
};
