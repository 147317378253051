import _ from "lodash";
import { polyfill } from "es6-promise";
import { createApp } from "vue";

import env from "./env";
import config from "./config";
import "./yup/yupCustomRules";
import i18n from "./i18n";
import pinia from "./stores";
import router from "./routes";
import vuetify from "./vuetify";
import directives from "./directives";
import App from "./App.vue";

polyfill();

window._ = _;

const app = createApp(App);
app.config.globalProperties.$env = env;
app.config.globalProperties.$config = config;
app.config.globalProperties.$baseUrl = env.BASE_URL;
app.use(i18n);
app.use(pinia);
app.use(router);
Object.keys(directives).forEach((name) => {
	app.directive(name, directives[name]);
});
app.use(vuetify);
app.mount("#app");
