import SanctumMiddleware from "@/middlewares/SanctumMiddleware";
import RouteExistsMiddleware from "@/middlewares/RouteExistsMiddleware";
import LocaleExistsMiddleware from "@/middlewares/LocaleExistsMiddleware";
import AuthPassedMiddleware from "@/middlewares/AuthPassedMiddleware";
import LocaleService from "@/services/LocaleService";
import RoutesService from "@/services/RoutesService";
import errorRoutes from "@/routes/error";
import authRoutes from "@/routes/auth";
import sharedRoutes from "@/routes/shared";
import adminRoutes from "@/routes/admin";
import redactorRoutes from "@/routes/redactor";
import userAdminRoutes from "@/routes/user_admin";
import userWorkerRoutes from "@/routes/user_worker";
import userSuperRedactorRoutes from "@/routes/user_super_redactor";

const routes = [
	{
		path: "",
		redirect: {
			name: "main",
			params: {
				locale: LocaleService.getLocale(),
			},
		},
	},
	{
		name: "main",
		path: `/:locale`,
		component: () => import("@/views/Index.vue"),
		meta: {
			middleware: [SanctumMiddleware, LocaleExistsMiddleware],
		},
		redirect: {
			name: RoutesService.routes.home,
		},
		children: [
			{
				name: "demo",
				path: "demo",
				component: () => import("@/views/pages/demo/DemoComponents.vue"),
				meta: {
					middleware: [AuthPassedMiddleware],
					layout: "default",
				},
			},
			{
				name: "auth",
				path: "",
				component: () => import("@/views/Blank.vue"),
				meta: {
					layout: "clear",
				},
				children: [...authRoutes],
			},
			{
				name: "app",
				path: "",
				component: () => import("@/views/Blank.vue"),
				meta: {
					middleware: [AuthPassedMiddleware],
					layout: "default",
				},
				children: [
					...sharedRoutes,
					{
						name: "admin",
						path: "admin",
						component: () => import("@/views/Blank.vue"),
						meta: {
							middleware: [AuthPassedMiddleware],
						},
						children: [...adminRoutes],
					},
					{
						name: "redactor",
						path: "redactor",
						component: () => import("@/views/Blank.vue"),
						meta: {
							middleware: [AuthPassedMiddleware],
						},
						children: [...redactorRoutes],
					},
					{
						name: "userAdmin",
						path: "user-admin",
						component: () => import("@/views/Blank.vue"),
						meta: {
							middleware: [AuthPassedMiddleware],
						},
						children: [...userAdminRoutes],
					},
					{
						name: "userWorker",
						path: "user-worker",
						component: () => import("@/views/Blank.vue"),
						meta: {
							middleware: [AuthPassedMiddleware],
						},
						children: [...userWorkerRoutes],
					},
					{
						name: "userSuperRedactor",
						path: "user-super-redactor",
						component: () => import("@/views/Blank.vue"),
						meta: {
							middleware: [AuthPassedMiddleware],
						},
						children: [...userSuperRedactorRoutes],
					},
				],
			},
			{
				name: "errors",
				path: "",
				component: () => import("@/views/Blank.vue"),
				meta: {
					layout: "error",
				},
				children: [...errorRoutes],
			},
		],
	},
	{
		path: "/:path(.*)*",
		component: () => import("@/views/Blank.vue"),
		meta: {
			middleware: [SanctumMiddleware, LocaleExistsMiddleware, RouteExistsMiddleware],
		},
	},
];

export default routes;
