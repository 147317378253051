export const PermissionEnums = {
	GENERAL_COMPANY_FILTER: "general_company_filter",
	GENERAL_DEPARTMENT_FILTER: "general_department_filter",
	GENERAL_COMPANIES_MANAGED_RECORDS: "general_companies_managed_records",
	GENERAL_MY_COMPANY_RECORDS: "general_my_company_records",
	GENERAL_SEE_COMPANY_DEBIT: "general_see_company_debit",

	COMPANIES_LIST: "companies_list",
	COMPANIES_MANAGE: "companies_manage",
	COMPANIES_MANAGE_TEMPORARY_REDACTOR: "companies_manage_temporary_redactor",

	USERS_LIST: "users_list",
	USERS_MANAGE: "users_manage",
	USERS_MANAGE_ADMIN: "users_manage_admin",
	USERS_MANAGE_REDACTOR: "users_manage_redactor",
	USERS_MANAGE_USER_ADMIN: "users_manage_user_admin",
	USERS_MANAGE_SUPER_REDACTOR: "users_manage_super_redactor",
	USERS_MANAGE_USER_WORKER: "users_manage_user_worker",
	USERS_MANAGE_DWU: "users_manage_dwu",

	INVOICES_LIST: "invoices_list",
	INVOICES_IMPORT: "invoices_import",

	SHIPPING_LIST: "shippings_list",
	SHIPPING_IMPORT: "shippings_import",

	ORDERS_LIST: "orders_list",
	ORDERS_IMPORT: "orders_import",

	RACKS_LIST: "racks_list",

	SETTINGS_MANAGE: "settings_manage",

	IMPORT_LOGS_LIST: "import_logs_list",

	DECLARATIONS_LIST: "declarations_list",
	DECLARATIONS_MANAGE: "declarations_manage",
};
