export const currencies = [
	{
		id: 'pln',
		slug: 'pln',
		default: true,
		name: {
			pl: 'Polski Złoty',
			en: 'Polish Zloty'
		}
	}
];