import {useCookies} from "@vueuse/integrations/useCookies";
import {useSanctumStore} from "@/stores/useSanctumStore";

export const SanctumMiddleware = ({to, next}) => {
	const { get } = useCookies();
	const csrfToken = get('XSRF-TOKEN');
	if (csrfToken) {
		return next();
	}

	const sanctumStore = useSanctumStore();

	sanctumStore.csrf()
		.finally(() => {
			return next();
		});
}

export default SanctumMiddleware;