import { defineStore } from "pinia";

export const useLoadingStore = defineStore("loading", {
	state: () => ({
		loadingRoute: {},
		loadingRouteClears: {},
		loadingRequest: {},
		loadingRequestClears: {},
	}),

	getters: {
		getLoadingRoute(state) {
			return Object.keys(state.loadingRoute).reduce((result, element) => {
				result ||= !!state.loadingRoute[element];
				return result;
			}, false);
		},
		getLoadingRequest(state) {
			return Object.keys(state.loadingRequest).reduce((result, element) => {
				result ||= !!state.loadingRequest[element];
				return result;
			}, false);
		},
		getLoading(state) {
			return this.getLoadingRoute || this.getLoadingRequest;
		},
	},

	actions: {
		routeStart(routeName) {
			if (routeName === undefined) return false;
			this.loadingRoute[routeName] = true;
			this.routeLoadingClearWithDelay(routeName);
		},
		routeStop(routeName) {
			this.loadingRoute[routeName] = false;
			this.routeLoadingClearWithDelay(routeName, false);
		},
		requestStart(requestUrl) {
			this.loadingRequest[requestUrl] = true;
			this.requestLoadingClearWithDelay(requestUrl);
		},
		requestStop(requestUrl) {
			delete this.loadingRequest[requestUrl];
			this.requestLoadingClearWithDelay(requestUrl, false);
		},
		requestStopAll() {
			Object.keys(this.loadingRequest).forEach((element) => {
				this.requestStop(element);
			});
		},
		routeLoadingClearWithDelay(routeName, startTimeout = true, delay = 5000) {
			if (!routeName) return false;
			if (this.loadingRouteClears[routeName]) {
				clearTimeout(this.loadingRouteClears[routeName]);
				delete this.loadingRouteClears[routeName];
			}
			if (!startTimeout) return false;

			this.loadingRouteClears[routeName] = setTimeout(() => {
				this.routeStop(routeName);
			}, delay);
		},
		requestLoadingClearWithDelay(requestUrl, startTimeout = true, delay = 20000) {
			if (!requestUrl) return false;
			if (this.loadingRequestClears[requestUrl]) {
				clearTimeout(this.loadingRequestClears[requestUrl]);
				delete this.loadingRequestClears[requestUrl];
			}
			if (!startTimeout) return false;

			this.loadingRequestClears[requestUrl] = setTimeout(() => {
				this.requestStop(requestUrl);
			}, delay);
		},
	},
});
